<template lang="html">

  <transition-group class="grid-items" name="brand-items" tag="section" @before-leave="beforeItemLeave">
    <template v-for="(item, index) in brands">
      <a v-if="item.brand_color && item.brand_logo" :href="'#' + item.title" :class="{ '-hide': currentSegment && !item.categories, '-active': activeBrand && activeBrand.id === item.id }" class="brand-item" :style="{ '--color-background': item.brand_color, 'order': index + 1 }" @click="setActiveBrand(item, index, $event)" :key="'item_' + item.title">
        <img :src="item.brand_logo" :alt="item.title + ' logo'" loading="lazy">
      </a>

      <article v-if="activeBrand && activeBrand.id === item.id" class="brand-expanded-view" :class="{ '-notransition': animationOverride }" :style="{ 'order': expandedViewOrder, '--max-height': expandedViewMaxHeight, '--pointer-pos': pointerPosition }" :key="'expanded_' + index" :ref="activeBrand.id">
        <div class="inner">
          <header class="view-header">
            <h3 class="title" v-html="activeBrand.title"></h3>

            <button class="close" @click="setActiveBrand(null, null, null)"><i class="icon" v-html="icon_close"></i><span class="sr-only">Close modal</span></button>

            <div class="categories" v-if="activeBrand.categories">
              <h4 class="category-label">{{ category_singular_label }}:</h4>

              <ul class="categories">
                <template v-for="(cat, index) in activeBrand.categories">
                  <span :key="'separator_' + cat.segment_id" class="separator" v-if="index > 0">, </span><li :key="cat.segment_id">{{ cat.segment_name }}</li>
                </template>
              </ul>
            </div>
          </header>

          <div class="view-content">
            <div class="image-column" v-if="item.brand_image || item.brand_image_png" :class="{ '-narrow': item.brand_image_png }">
              <div class="image-wrap" :class="{ '-auto-height' : item.brand_image_png, '-square' : !item.brand_image_png }">
                <picture>
                  <source :srcset="item.brand_image_png ? item.brand_image_png.srcset : item.brand_image.srcset" sizes="(min-width: 744px) 50vw, 100vw">
                  <img :alt="item.brand_image_png ? item.brand_image_png.alt : item.brand_image.alt" :src="item.brand_image_png ? item.brand_image_png.src : item.brand_image.src" loading="lazy">
                </picture>
              </div>
            </div>

            <div class="text-column">
              <div class="excerpt" v-if="activeBrand.excerpt" v-html="activeBrand.excerpt"></div>
              <div class="paragraph" v-if="activeBrand.paragraph" v-html="activeBrand.paragraph"></div>

              <div class="buttons" v-if="activeBrand.read_more_uri && activeBrand.read_more_label && activeBrand.read_more_target">
                <a class="button read-more" :href="activeBrand.read_more_uri" rel="noopener noreferrer" :target="activeBrand.read_more_target">{{ activeBrand.read_more_label }}<i class="icon arrow" v-html="icon_arrow"></i></a>
              </div>
            </div>
          </div>
        </div>
      </article>
    </template>
  </transition-group>

</template>

<script>

export default {
  name: 'sortableGrid',
  data: function() {
    return {
     activeBrandOrder: 99,
     activeBrandIndex: null,
     activeID: null,
     expandedViewMaxHeightVal: 0,
     // windowWidth: 300,
     animationOverride: false,
    }
  },
  props: [
    'brands',
    'icon_close',
    'icon_link_external',
    'icon_arrow',
    'currentSegment',
    'windowWidth',
    'category_singular_label',
  ],
  computed: {
    activeBrand: function() {
      return this.brands.filter(item => item.id === this.activeID)[0]
    },
    expandedViewMaxHeight: function() {
      if (this.expandedViewMaxHeightVal) {

        return this.expandedViewMaxHeightVal / 10 + 'rem'
        // return '100vh'
      }
      return 0;
    },
    // set the expanded brand view's pointer position, accounting for breakpoints
    pointerPosition: function() {
      // return this.activeBrandOrder
      var x;
      var o = this.activeBrandOrder;
      if (this.windowWidth >= 1200) {
      // if (this.windowWidth >= 1440) {
        // return o % 4
        x = o % 6;

        if (x === 0) {
          return 6
        } else {
          return x
        }

      } else if (this.windowWidth >= 992) {
        // return o % 4
        x = o % 4;

        if (x === 0) {
          return 4
        } else {
          return x
        }

      } else if (this.windowWidth >= 744) {
        x = o % 3;
        if (x === 0) {
          return 3
        } else {
          return x
        }
      } else {
        x = o % 2;
        if (x === 0) {
          return 2
        } else {
          return x
        }
      }
    },
    expandedViewOrder: function() {
      if (this.activeBrandOrder) {
        var o = this.activeBrandOrder;

        return this.getExpandedViewOrder(o);
      }
      return 999
    },
    // expandTransitionMod: function() {
    //   if (this.animationOverride) {
    //     return 0
    //   } else {
    //     return 1
    //   }
    // }
  },
  methods: {
    closeBrandView: function() {
      this.animationOverride = false;

      this.$nextTick(() => {
        this.activeID = null
        this.activeBrandOrder = 999
      })
    },
    openBrandView: function(brand, index) {
      this.activeID = brand.id

      if (index !== null) {
        if (this.getExpandedViewOrder(this.activeBrandOrder) === this.getExpandedViewOrder(index + 1)) {
          this.animationOverride = true;
        } else {
          this.animationOverride = false;
        }

        this.activeBrandOrder = index + 1;
      }
    },
    setActiveBrand: function(brand, index, event) {
      if (event) {
        event.preventDefault();
      }

      if (brand) {
        if (this.activeBrand) {
          if (this.activeBrand.id === brand.id) {
            this.closeBrandView();
          } else {
            this.closeBrandView();
            this.$nextTick(() => {
              this.openBrandView(brand, index);
            })
          }
        } else {
          this.openBrandView(brand, index);
        }
      } else {
        this.closeBrandView();
      }

      this.$nextTick(() => {
        if (this.activeBrand && this.activeBrand.id && this.$refs[this.activeBrand.id] && this.$refs[this.activeBrand.id][0] && this.$refs[this.activeBrand.id][0].querySelector('.inner')) {
          this.expandedViewMaxHeightVal = this.$refs[this.activeBrand.id][0].querySelector('.inner').getBoundingClientRect().height
        }
      })
    },
    // set order of the expanded view, so it appears below a complete row of items (accounting for breakpoints), regardless of the active item's position in said row
    getExpandedViewOrder: function(o) {
      var x;
      if (this.windowWidth >= 1200) {
      // if (this.windowWidth >= 1440) {

        x = o % 6;

        if (x === 0) {
          return o + 1
        } else {
          return o + (7 - x)
        }


      } else if (this.windowWidth >= 992) {

        x = o % 4;

        if (x === 0) {
          return o + 1
        } else {
          return o + (5 - x)
        }

      } else if (this.windowWidth >= 744) {
        x = o % 3;

        if (x === 0) {
          return o + 1
        } else {
          return o + (4 - x)
        }

      } else {
        x = o % 2;

        if (x === 0) {
          return o + 1
        } else {
          return o + 2
        }
      }
    },
    // maintain a transitioning item's position and dimensions while it is absolutely positioned
    beforeItemLeave(el) {
      if (el.classList.contains('brand-item') || el.classList.contains('brand-expanded-view')) {
        const {marginLeft, marginTop, width, height} = window.getComputedStyle(el)
        el.style.left = `${el.offsetLeft - parseFloat(marginLeft, 10)}px`
        el.style.top = `${el.offsetTop - parseFloat(marginTop, 10)}px`
        el.style.width = width
        el.style.height = height
      }
    },
  },
  mounted() {
    // console.log(window.brandData);
  },
}
</script>
