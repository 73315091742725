import Vue from 'vue'
import App from './App.vue'

// Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#brandGrid')


// new Vue({
//   el: '#brandGrid',
//   components: {
//     App
//   }
// }).$mount('#brandGrid')




// import Vue from 'vue';
// import wrap from '@vue/web-component-wrapper';
// import VueWebComponent from './components/SortableGrid';
//
// const CustomElement = wrap(Vue, VueWebComponent);
//
// window.customElements.define('brand-grid', CustomElement);
