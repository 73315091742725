<template>
  <div class="brand-grid">
    <div v-if="segments && show_filter && !isMobile" class="brand-filter">
      <h3 class="sr-only">{{ i18n.BRANDS_FILTER_BY_SEGMENT }}</h3>
      <button class="button filter-segments" @click="filterSegments(null)">{{ i18n.SEGMENTS_SHOW_ALL }} ({{ allBrands.length }})</button>
      <button class="button filter-segments" :class="{'-active': currentSegment === segment.segment_id}" @click="filterSegments(segment.segment_id)" v-for="segment in segments" :key="'filter_btn_' + segment.segment_id">{{ segment.segment_name }} ({{ segment.count }})</button>
    </div>

    <label class="sr-only" v-if="segments && show_filter && isMobile" for="filterBrands">{{ i18n.BRANDS_FILTER_BY_SEGMENT }}</label>

    <select id="filterBrands" v-if="segments && show_filter && isMobile" class="brand-filter-select" @change="filterSegments($event.target.value)">
      <option :value="null">{{ i18n.CATEGORY_SINGULAR_LABEL }}: {{ i18n.SEGMENTS_SHOW_ALL }} ({{ allBrands.length }})</option>
      <option v-for="segment in segments" :key="'filter_opt_' + segment.segment_id" :value="segment.segment_id">{{ i18n.CATEGORY_SINGULAR_LABEL }}: {{ segment.segment_name }} ({{ segment.count }})</option>
    </select>

    <sortable-grid ref="gridComponent" :current-segment="currentSegment" :window-width="windowWidth" :brands="brands" v-if="brands" :icon_close="icon_close" :icon_link_external="icon_link_external" :icon_arrow="icon_arrow" :category_singular_label="i18n.CATEGORY_SINGULAR_LABEL"></sortable-grid>

    <footer class="component-footer buttons" v-if="otherBrandsAreAvailable">
      <button class="button read-more load-more" @click="loadMore">
        <span>{{ i18n.BRANDS_LOAD_MORE }}</span>
        <i class="icon arrow arrow-down" v-html="icon_arrow"></i>
      </button>
    </footer>
  </div>
</template>

<script>
import SortableGrid from './components/SortableGrid.vue'

export default {
  name: 'App',
  data: function() {
    return {
      currentSegment: null,
      currentPage: 1,
      itemsPerPage: 12,
      windowWidth: 300,
    }
  },
  components: {
    SortableGrid
  },
  computed: {
    // weed out any brands missing logo/brand colour, add an id to the remaining items, and return them
    allBrands: function() {
      var arr = [...window.brandData.brands];

      for (var i = 0; i < arr.length; i++) {
        if (arr[i].brand_color && arr[i].brand_logo && arr[i].title) {
          arr[i].id = 'item_' + i;
        }
      }

      return arr.filter(brand => brand.id)
    },
    currentBrandCount: function () {
      return this.itemsPerPage * this.currentPage
    },
    // take the list of brands defined above, filter them by their segment and/or the current number of visible items, and return the hits. These are the brands displayed on the page
    brands: function() {
      // return window.brandData.brands
      var aTheBrands;

      if (this.currentSegment) {
        aTheBrands = this.allBrands.filter(brand => this.segments[this.currentSegment].items.includes(brand.id));
      } else {
        aTheBrands = this.allBrands
      }

      if (this.show_filter) {
        return aTheBrands
      } else {
        return aTheBrands.filter(brand => aTheBrands.indexOf(brand) < this.currentBrandCount)
      }
    },
    i18n: function() {
      return window.brandData.i18n
    },
    show_filter: function() {
      return window.brandData.show_filter
    },
    // find all segments/categories defined in each brand, and build a list of segments for the brand filter
    segments: function() {
      var oTheSegments = {};

      this.allBrands.forEach((brand) => {
        if (brand.categories && brand.categories.length) {
          brand.categories.forEach((category) => {
            if (!oTheSegments[category.segment_id]) {
              oTheSegments[category.segment_id] = JSON.parse(JSON.stringify(category));
              oTheSegments[category.segment_id]['count'] = 1;
              oTheSegments[category.segment_id]['items'] = [brand.id];
            } else {
              oTheSegments[category.segment_id].count += 1;
              oTheSegments[category.segment_id].items.push(brand.id);
            }
          });
        }
      });

      return oTheSegments
    },
    // only show "load more" if there are more brands to load
    otherBrandsAreAvailable: function() {
      if (this.currentSegment) {
        var aTheBrands = this.allBrands.filter(brand => this.segments[this.currentSegment].items.includes(brand.id));

        if (aTheBrands.length > this.brands.length) {
          return true
        }
        return false
      } else {
        if (this.brands.length < this.allBrands.length) {
          return true
        }
        return false
      }
    },

    icon_arrow: function() {
      return window.brandData.icon_arrow
    },

    icon_close: function() {
      return window.brandData.icon_close
    },

    icon_link_external: function() {
      return window.brandData.icon_link_external
    },

    isMobile: function() {
      if (this.windowWidth < 744) {
        return true
      }
      return false
    }
  },
  methods: {
    filterSegments: function(id) {
      this.$refs.gridComponent.setActiveBrand(null, null, null);
      this.currentSegment = id;
    },
    filterTheSegments: function(noe) {
      console.log(noe);
    },
    loadMore: function() {
      this.currentPage += 1;
    },
    handleResize: function() {
      this.windowWidth = window.innerWidth;
    },
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  }
}
</script>
